<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <div class="pad-container flx-row ali-c">
        <el-input
          placeholder="请输入商品编码"
          class="search-input"
          v-model.trim="searchObj.goodsCode"
        ></el-input>
        <el-input
          placeholder="请输入商品名称"
          class="search-input ml-15"
          v-model.trim="searchObj.goodsName"
        ></el-input>
        <el-cascader
          class="ml-15"
          v-model="classifyId"
          :options="classifyList"
          :props="props"
          :show-all-levels="false"
          placeholder="请选择分类"
        ></el-cascader>
        <el-select
          placeholder="请选择商品状态"
          class="search-input ml-15"
          v-model="searchObj.status"
        >
          <el-option
            v-for="(item, index) in goodsStatusOptions"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-button
          type="primary"
          icon="el-icon-search"
          class="ml-15"
          @click="search"
          >查询</el-button
        >
        <el-button icon="el-icon-refresh" class="ml-15" @click="reset"
          >重置</el-button
        >
      </div>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <el-tabs
            v-model="activePart"
            @tab-click="changeTab"
            v-if="!storeFlag"
          >
            <el-tab-pane
              :label="item.storeName"
              :name="item.id + ''"
              v-for="(item, index) in shopList"
              :key="index"
            ></el-tab-pane>
          </el-tabs>
          <div>
            <el-button
              type="primary"
              size="medium"
              icon="el-icon-plus"
              @click="handleEdit()"
              >添加</el-button
            >
            <!-- 表格 -->
            <el-table class="mt-24 member-table" :data="tableData">
              <el-table-column label="序号">
                <template slot-scope="scope">{{
                  scope.$index + 1 + (pageNum - 1) * pageSize
                }}</template>
              </el-table-column>
              <el-table-column
                label="商品条码(UPC)"
                prop="goodsBarcode"
                width="200"
              ></el-table-column>
              <el-table-column
                label="商品编码"
                prop="goodsCode"
              ></el-table-column>
              <el-table-column
                label="商品名称"
                prop="goodsName"
              ></el-table-column>
              <el-table-column
                label="一级分类"
                prop="classifyName"
              ></el-table-column>
              <el-table-column
                label="二级分类"
                prop="secondClassifyName"
              ></el-table-column>
              <el-table-column
                label="所属门店"
                prop="storeName"
              ></el-table-column>
              <el-table-column
                label="实价（元）"
                prop="actualPrice"
              ></el-table-column>
              <el-table-column
                label="原价（元）"
                prop="originalPrice"
              ></el-table-column>
              <el-table-column
                label="月销量"
                prop="monthSales"
              ></el-table-column>
              <el-table-column label="库存" prop="stock"></el-table-column>
              <el-table-column
                label="重量（g）"
                prop="goodsWeight"
              ></el-table-column>
              <el-table-column
                label="商品角标"
                prop="cornerMarkName"
              ></el-table-column>
              <el-table-column label="上架状态" prop="status">
                <template slot-scope="scope">
                  <div class="flx-row">
                    <el-switch
                      v-model="scope.row.status"
                      :active-value="1"
                      :inactive-value="0"
                      @change="toggle(scope.row)"
                    ></el-switch>
                    <span class="ml-5">{{
                      scope.row.status ? "上架" : "下架"
                    }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="160">
                <template slot-scope="scope">
                  <div class="flx-row ali-c">
                    <el-link
                      type="primary"
                      :underline="false"
                      @click="handleEdit(scope.row.goodsId)"
                    >
                      <i class="el-icon-edit-outline"></i>
                      编辑
                    </el-link>
                    <el-link
                      type="primary"
                      :underline="false"
                      @click="handleDel(scope.row.goodsId)"
                      class="ml-10"
                    >
                      <i class="el-icon-delete"></i>
                      删除
                    </el-link>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <Pagination
              :total="total"
              :pageNum="pageNum"
              :pageSize="pageSize"
              :pageSizes="pageSizes"
              @syncPageData="syncPageData"
            />
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  queryGoodsType,
  listOfPage,
  delGoodsFreshInfo,
  upAndDownGoods,
  queryGoodsList,
} from "@/api/market/flesh_list";
export default {
  name: "goods_list",
  components: {
    Pagination,
  },
  data() {
    return {
      props: {
        label: "classifyName",
        value: "id",
        children: "childList",
        // expandTrigger: 'hover',
        // checkStrictly: 'true'
      },
      activePart: "1",
      // 顶部搜索栏数据
      searchObj: {
        goodsCode: "",
        goodsName: "",
        status: "",
      },
      classifyId: [],
      // 一级分类下拉选项
      classifyList: [],
      // 二级分类下拉选项
      secondClassifyOptions: [
        { id: 0, name: "二级分类1" },
        { id: 1, name: "二级分类2" },
        { id: 2, name: "二级分类3" },
      ],
      // 商品状态下拉选项
      goodsStatusOptions: [
        { id: 0, name: "下架" },
        { id: 1, name: "上架" },
      ],
      // 是否完善信息下拉选项
      completeInfoOptions: [
        { id: 0, name: "是" },
        { id: 1, name: "否" },
      ],

      // 拼团表格数据内容
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      shopList: [],
      upFlag: true,
      storeId: "",
      storeFlag: false,
    };
  },
  mounted() {
    if (this.$store.getters.storeId) {
      this.storeId = this.$store.getters.storeId;
      this.storeFlag = true;
      this.getGoodsList();
    } else {
      this.getStoreList();
    }
    this.getClassifyList();
  },
  methods: {
    // 获取分类列表
    getClassifyList() {
      queryGoodsType().then((res) => {
        this.classifyList = res.data.body;
      });
    },
    //获取门店列表
    getStoreList() {
      listOfPage().then((res) => {
        this.shopList = res.data.body.list;
        this.storeId = this.shopList[0].id;
        this.getGoodsList();
      });
    },
    goSee() {
      this.$router.push("/login");
    },
    // 删除
    handleDel(id) {
      delGoodsFreshInfo(id).then((res) => {
        this.$message.success(res.data.body);
        this.getGoodsList();
      });
    },
    // 编辑
    handleEdit(id) {
      this.$router.push({ name: "Flesh_new", query: { id } });
    },
    // 上下架验证
    toggle(row) {
      let flag = row.status;
      row.status = row.status ? 0 : 1;
      if (row.status) {
        this.$confirm("确定要下架吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(() => {
          this.toggleReq(row, flag);
        });
      } else {
        this.toggleReq(row, flag);
      }
    },
    // 上下架请求
    toggleReq(row, flag) {
      // console.log(row, flag);
      let data = {
        id: row.goodsId,
        status: row.status ? 0 : 1,
      };
      upAndDownGoods(data).then((res) => {
        row.status = flag ? 1 : 0;
        this.getGoodsList();
      });
    },
    // 切换tab
    changeTab(tab) {
      this.storeId = tab.name;
      this.pageNum = 1;
      this.getGoodsList();
    },
    //数据初始化
    getGoodsList() {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        storeId: Number(this.storeId),
      };
      data = Object.assign(data, this.searchObj);
      let length = this.classifyId.length;
      if (length) {
        data.classifyId = this.classifyId[length - 1];
      }
      queryGoodsList(data).then((res) => {
        let data = res.data.body;
        this.total = data.total;
        this.tableData = data.list;
      });
    },
    // 搜索
    search() {
      this.pageNum = 1;
      this.getGoodsList();
    },
    // 重置搜索关键词
    reset() {
      for (let key in this.searchObj) {
        this.searchObj[key] = "";
      }
      this.classifyId = [];
      this.search();
    },
    // 页面列表 pageNum pageSize获取
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
      this.getGoodsList();
    },
  },
  filters: {
    statusFilter(val) {
      switch (val) {
        case 0:
          return "等待中";
        case 1:
          return "进行中";
        case 2:
          return "已结束";
        default:
          return "状态未知";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.el-avatar /deep/ img {
  width: 100%;
}
</style>
